import type Highcharts from 'highcharts'
import { DateTime } from 'luxon'
import { useTranslations } from 'next-intl'
import { useRef } from 'react'
import { match } from 'ts-pattern'

import { formatLocaleDate, setTZAndLocale } from '@/logged-in/utils/commonUtils'
import { useStore } from '@/shared/components/SpotPrices/StoreProvider'
import { useBreakpoints } from '@/shared/hooks/useBreakpoints'
import { useTheme } from '@/shared/hooks/useTheme'
import { useFormatter, useLocale } from '@/shared/locale'
import { isDeepEqual } from '@/shared/utils/isDeepEqual'

import {
  renderDecreasingIndicator,
  renderHighestIndicators,
  renderIncreasingIndicator,
  renderLowestIndicators,
  renderTimeIndicator,
} from './renderers'
import SpotTooltip, { getTooltipHeading } from './Tooltip'
import type { SpotEntry } from '../commons'
import {
  CrosshairId,
  TooltipContainerId,
  getPositiveNegativeColorFromPrice,
  useIsDuo,
} from '../commons'
import type { SpotGraphContextPoint } from '../utils'
import { computeAggregates, computeSpotGraphData, getColorFromPalette, updateZones } from '../utils'

type SVGsMap = Record<
  'dot' | 'lowest' | 'highest' | 'increasing' | 'decreasing',
  Highcharts.SVGElement[] | undefined
>

const TICK_AMOUNT = 5

const FONT_FAMILY = 'FortumSans,arial,sans-serif'

const handleScrollEvent = (chart: Highcharts.Chart) => {
  window.addEventListener('scroll', function handler() {
    if (chart.tooltip) {
      chart.tooltip.hide(0)
    } else {
      window.removeEventListener('scroll', handler)
    }
  })
}

let hideTooltipTimeoutId: number

// This function is used to force padding between series and axis. Apparently
// highcharts won't increase padding if value is close to 0 but still positive
const getMin = (range: number, minValue: number) => {
  if (minValue < 0) {
    return
  }
  const minAcceptableValue = (range / TICK_AMOUNT) * 0.3
  if (minValue < minAcceptableValue) {
    return -1 * minAcceptableValue
  }
  return
}

const drawNativeCrosshair = (chart: Highcharts.Chart, timeZone: string) => {
  chart.xAxis?.[0].drawCrosshair(
    undefined,
    chart?.series[0].points[DateTime.now().setZone(timeZone).hour],
  )
}

const drawOwnCrosshair = (chart: Highcharts.Chart, timeZone: string, offsetX?: number) => {
  const isShort = offsetX == null
  if (offsetX == null) {
    offsetX =
      (chart?.series?.[0]?.points[DateTime.now().setZone(timeZone).hour]?.plotX as number) ?? 0
  }

  const tooltipPosition = document
    .querySelector(`#${TooltipContainerId}`)
    ?.parentElement?.getBoundingClientRect()
  const dashedLineRawElement = document.querySelector(`#${CrosshairId}`)

  // Ensure all required elements are defined
  if (!dashedLineRawElement || !tooltipPosition || !chart?.container) {
    return
  }

  const dashedLined = dashedLineRawElement as HTMLDivElement
  const chartContainerRect = chart.container.getBoundingClientRect()
  const height = isShort
    ? chart.plotHeight
    : chartContainerRect.top +
      chart.plotHeight +
      chart.plotTop -
      (tooltipPosition.y + tooltipPosition.height)
  const top = isShort
    ? chart.plotTop
    : tooltipPosition.y + tooltipPosition.height - chartContainerRect.top

  dashedLined.style.borderRight = '1px dashed'
  dashedLined.style.height = `${height}px`
  dashedLined.style.position = 'absolute'
  dashedLined.style.zIndex = '20'
  dashedLined.style.left = `${chart.plotLeft + offsetX}px`
  dashedLined.style.top = `${top}px`
}

const registerProxiesForChart = (chart: Highcharts.Chart, isMobile: boolean, timeZone: string) => {
  chart.tooltip.hide = new Proxy(chart.tooltip.hide, {
    apply(target, thisArg, argArray) {
      hideTooltipTimeoutId = window.setTimeout(
        () => {
          if (isMobile) {
            drawOwnCrosshair(chart, timeZone)
          } else {
            drawNativeCrosshair(chart, timeZone)
          }
        },
        (chart.tooltip.options.hideDelay ?? 0) + 1,
      )
      Reflect.apply(target, thisArg, argArray)
    },
  })

  chart.tooltip.refresh = new Proxy(chart.tooltip.refresh, {
    apply(target, thisArg, argArray) {
      window.clearTimeout(hideTooltipTimeoutId)
      Reflect.apply(target, thisArg, argArray)
    },
  })

  if (!isMobile) {
    return
  }
  chart.xAxis[0].drawCrosshair = new Proxy(chart.xAxis[0].drawCrosshair, {
    apply(_, thisArg, argArray) {
      const shouldRenderShortCrosshair = argArray[0]?.type === 'mouseleave'
      drawOwnCrosshair(
        thisArg.chart,
        timeZone,
        shouldRenderShortCrosshair ? undefined : argArray[1].clientX,
      )
    },
  })
}

let cacheZones: Highcharts.SeriesZonesOptionsObject[] = []
export const useSpotConfig = (
  data: SpotEntry[],
  dateMode: 'today' | 'tomorrow' | null,
  timeZone: string,
  isVatIncluded: boolean,
) => {
  const svgs = useRef<SVGsMap>({
    dot: undefined,
    lowest: undefined,
    highest: undefined,
    increasing: undefined,
    decreasing: undefined,
  })
  const priceUnit = useStore((s) => s.priceUnit)
  const { isMobile, isTablet, isDesktop } = useBreakpoints()
  const { colors } = useTheme()
  const t = useTranslations('spotPrices')
  const chartType = 'line'
  const locale = useLocale()
  const { number } = useFormatter()
  const layout = useStore((s) => s.layout)
  const isDuo = useIsDuo()
  const isToday = dateMode === 'today'

  const categories = data.map((entry) => {
    const date = setTZAndLocale(entry.time, timeZone, locale)
    return formatLocaleDate(date, isMobile || isTablet || layout === 'compact' ? `HH` : `HH.mm`)
  })

  const chartHeight = match(true as boolean)
    .with(isMobile, () => 257)
    .with(isTablet, () => 268)
    .with(isDesktop, () => 264)

  const spotGraphData = computeSpotGraphData(data, isVatIncluded)
  const { average, max, min, range, isEmpty } = computeAggregates(data, isVatIncluded)

  const drawCrosshair = (chart: Highcharts.Chart) => {
    if (isMobile) {
      drawOwnCrosshair(chart, timeZone)
    } else {
      drawNativeCrosshair(chart, timeZone)
    }
  }

  const config: Highcharts.Options = {
    credits: {
      enabled: false,
    },
    legend: {
      enabled: false,
    },
    boost: {
      debug: {
        timeSeriesProcessing: true,
        timeRendering: true,
      },
    },
    chart: {
      animation: false,
      marginTop: 40,
      marginBottom: 32,
      backgroundColor: `transparent`,
      spacingLeft: -10,
      spacingRight: 0,
      height: `${chartHeight}px`,
      events: {
        load() {
          const points = this.series[0].points as SpotGraphContextPoint[]
          const newZones = updateZones(points, (point) =>
            point.priceMissing
              ? colors.backgroundPrimary
              : getColorFromPalette([
                  [0, colors.chart5],
                  [average / max + 0.1, colors.chart1],
                ]),
          )

          this.series[0].update({ type: chartType, zones: newZones })
        },

        render() {
          // eslint-disable-next-line @typescript-eslint/no-this-alias, unicorn/no-this-assignment
          const chart = this

          registerProxiesForChart(chart, isMobile, timeZone)

          drawCrosshair(chart)

          const svgItems = svgs.current
          if (svgItems) {
            for (const items of Object.values(svgItems)) {
              if (items) {
                for (const item of items) {
                  item.destroy()
                }
              }
            }
          }

          const rendererMode = isMobile ? 'mobile' : 'tablet'

          svgs.current = {
            dot: isToday ? renderTimeIndicator(chart, colors, rendererMode, timeZone) : undefined,
            lowest: !isDuo ? renderLowestIndicators(chart, colors, rendererMode) : undefined,
            highest: !isDuo ? renderHighestIndicators(chart, colors, rendererMode) : undefined,
            increasing: isDuo ? renderIncreasingIndicator(chart, colors, rendererMode) : undefined,
            decreasing: isDuo ? renderDecreasingIndicator(chart, colors, rendererMode) : undefined,
          }

          const points = this.series[0].points as SpotGraphContextPoint[]
          const newZones = updateZones(points, (point) =>
            point.priceMissing
              ? colors.backgroundPrimary
              : getColorFromPalette([
                  [0, colors.chart5],
                  [average / max + 0.1, colors.chart1],
                ]),
          )

          if (!isDeepEqual(newZones, cacheZones)) {
            cacheZones = structuredClone(newZones)

            this.series[0].update({
              type: chartType,
              zones: newZones,
            })
          }
        },
      },
    },
    title: {
      text: undefined,
    },
    series: [
      {
        type: chartType,
        zIndex: 3,
        name: 'spot',
        zoneAxis: 'x',
        zones: [],
        marker: {
          enabled: false,
        },
        step: 'center',
        accessibility: {
          point: {
            descriptionFormatter: (point) => {
              const contextPoint = point as SpotGraphContextPoint
              return `${getTooltipHeading(new Date(contextPoint.time), timeZone, locale)}, ${
                contextPoint.y || 0
              } ${contextPoint.unit}`
            },
          },
        },
        animation: false,
        color: getColorFromPalette([
          [0, colors.textAlert],
          [average / max + 0.1, colors.primary],
        ]),
        data: spotGraphData,
        lineWidth: 3,
        states: {
          hover: {
            lineWidth: 3,
          },
        },
        yAxis: 0,
      },
    ],
    plotOptions: {
      series: {
        marker: {
          states: {
            hover: {
              enabled: false,
            },
          },
        },
      },
    },
    yAxis: {
      tickAmount: TICK_AMOUNT,
      labels: {
        style: {
          fontSize: isMobile ? `12px` : `14px`,
          fontFamily: FONT_FAMILY,
          color: colors.text,
        },
        y: 3,
      },
      min: isEmpty ? 0 : getMin(range, min),
      max: isEmpty ? 100 : undefined,
      title: {
        style: {
          fontSize: isMobile ? `12px` : `14px`,
          fontFamily: FONT_FAMILY,
          color: colors.text,
        },
        text: priceUnit,
        offset: 0,
        y: -22,
        x: 13,
        rotation: 0,
        align: 'high',
      },
      plotLines: isDuo
        ? [
            {
              value: average,
              color: colors.background,
              width: 4,
              zIndex: 10,
            },
            {
              value: average,
              color: colors['chart7-1'],
              width: 2,
              zIndex: 11,
            },
          ]
        : [],
    },

    xAxis: {
      crosshair: isMobile
        ? {
            color: 'transparent',
            width: 0.1,
          }
        : {
            color: colors.text,
            dashStyle: 'Dash',
            width: 1,
          },
      offset: -10,
      zIndex: 2,
      lineWidth: 0,
      title: {
        text: null,
      },
      labels: {
        //isMobile && layout === 'wide' ||   ? 2 : 1
        step: isMobile ? 2 : 1,
        autoRotation: [isDesktop && layout === 'wide' ? -20 : 0],
        style: {
          fontSize: isMobile ? `12px` : `14px`,
          fontFamily: FONT_FAMILY,
          color: colors.text,
        },
      },
      categories,
      tickWidth: 0,
    },
    tooltip: {
      animation: false,
      shadow: false,
      borderWidth: 0,
      borderRadius: 0,
      padding: 0,
      shared: true,
      shape: 'rect',
      backgroundColor: 'transparent',
      hideDelay: 100,
      formatter: function () {
        const point = this as SpotGraphContextPoint
        return SpotTooltip({
          heading: getTooltipHeading(new Date(point.time), timeZone, locale),
          price:
            point.effectivePrice != null
              ? number(point.effectivePrice, { minimumFractionDigits: 2, maximumFractionDigits: 2 })
              : '',
          unit: point.unit,
          color: getPositiveNegativeColorFromPrice(point.effectivePrice, average),
          colors,
          rendererMode: isMobile ? 'default' : 'tablet',
          labels: { dataNotAvailable: t('dataNotAvailable') },
        })
      },
      useHTML: true,
      outside: true,
      positioner: isMobile
        ? function (_, height) {
            const thiz = this as unknown as Highcharts.Tooltip
            const chart = thiz.chart
            const chartPosition = chart.pointer.getChartPosition()
            const tooltipPosition = document
              .querySelector(`#${TooltipContainerId}`)
              ?.getBoundingClientRect()

            handleScrollEvent(chart)

            return {
              x: -chartPosition.left + (tooltipPosition?.left ?? 0),
              y: chart.plotTop - height - 65,
            }
          }
        : undefined,
    },
  }

  return config
}
