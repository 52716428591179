/**
 * Performs a deep comparison between two values to determine if they are equivalent.
 *
 * @param objectA - First value to compare
 * @param objectB - Second value to compare
 * @returns True if the values are deeply equal, false otherwise
 */

export const isDeepEqual = <T>(objectA: T, objectB: T): boolean => {
  if (objectA === objectB) {
    return true
  }

  if (
    objectA === null ||
    objectB === null ||
    typeof objectA !== 'object' ||
    typeof objectB !== 'object'
  ) {
    return false
  }

  if (Array.isArray(objectA) && Array.isArray(objectB)) {
    if (objectA.length !== objectB.length) {
      return false
    }

    for (const [i, element] of objectA.entries()) {
      if (!isDeepEqual(element, objectB[i])) {
        return false
      }
    }
    return true
  }

  if (Array.isArray(objectA) !== Array.isArray(objectB)) {
    return false
  }

  if (objectA instanceof Date && objectB instanceof Date) {
    return objectA.getTime() === objectB.getTime()
  }

  const keysA = Object.keys(objectA)
  const keysB = Object.keys(objectB)

  if (keysA.length !== keysB.length) {
    return false
  }

  return keysA.every(
    (key) =>
      objectB.hasOwnProperty(key) &&
      isDeepEqual(
        (objectA as Record<string, unknown>)[key],
        (objectB as Record<string, unknown>)[key],
      ),
  )
}
