import { type Chart } from 'highcharts'
import type Highcharts from 'highcharts'
import { DateTime } from 'luxon'
import { useTranslations } from 'next-intl'

import { useBreakpoints } from '@/shared/hooks/useBreakpoints'
import { useTheme } from '@/shared/hooks/useTheme'
import { useFormatter, useLocale } from '@/shared/locale'
import { isDeepEqual } from '@/shared/utils/isDeepEqual'

import type { SpotEntry } from '../commons'
import { TooltipContainerId, getPositiveNegativeColorFromPrice } from '../commons'
import SpotTooltip, { getYearlyToolTipheading } from '../Graph/Tooltip'
import { useStore } from '../StoreProvider'
import type { SpotGraphContextPoint } from '../utils'
import { computeAggregates, computeCategories, computeSpotGraphData, updateZones } from '../utils'

export type SpotGraphSerie = Highcharts.Series & {
  zones: Highcharts.SeriesZonesOptionsObject[]
  isDirty: boolean
}

const TICK_AMOUNT = 5
const FONT_FAMILY = 'FortumSans,arial,sans-serif'

export const formatYearlyTimeSpan = (date: Date, locale: string) => {
  const luxonDate = DateTime.fromJSDate(date).setLocale(locale)

  const month = luxonDate.toLocaleString({ month: 'long' })
  return `${month}`
}

const handleScrollEvent = (chart: Chart) => {
  window.addEventListener('scroll', function handler() {
    if (chart.tooltip) {
      chart.tooltip.hide(0)
    } else {
      window.removeEventListener('scroll', handler)
    }
  })
}

let cacheZones: Highcharts.SeriesZonesOptionsObject[] = []
export const useYearlySpotConfig = (data: SpotEntry[]) => {
  const { isMobile, isTablet, isDesktop } = useBreakpoints()
  const { colors } = useTheme()
  const t = useTranslations('spotPrices')
  const { number } = useFormatter()
  const chartType = 'column'
  const locale = useLocale()
  const isVatIncluded = useStore((s) => s.isVatIncluded)

  const categories = computeCategories(data, locale, 'LLL').map((cat) =>
    cat
      .slice(0, 3)
      .replaceAll('.', '')
      .replace(/^\w/, (c) => c.toUpperCase()),
  )

  const chartHeight = (() => {
    if (isMobile) {
      return 257
    }
    if (isTablet) {
      return 268
    }
    if (isDesktop) {
      return 264
    }
  })()

  const spotGraphData = computeSpotGraphData(data, isVatIncluded)
  const { average, isEmpty } = computeAggregates(data, isVatIncluded)

  const config: Highcharts.Options = {
    credits: {
      enabled: false,
    },
    legend: {
      enabled: false,
    },
    boost: {
      debug: {
        timeSeriesProcessing: true,
        timeRendering: true,
      },
    },
    chart: {
      marginTop: 40,
      marginBottom: 32,
      backgroundColor: `transparent`,
      spacingLeft: -10,
      spacingRight: 0,
      height: `${chartHeight}px`,
      events: {
        load() {
          const points = this.series[0].points as SpotGraphContextPoint[]
          const newZones = updateZones(points, (point) =>
            point.priceMissing ? colors.backgroundPrimary : colors.chart1,
          )
          this.series[0].update({ type: chartType, zones: newZones })
        },
        render() {
          if (this.series.length > 0 && this.series[0].graph) {
            this.series[0].graph.destroy()
          }
          const points = this.series[0].points as SpotGraphContextPoint[]
          const newZones = updateZones(points, (point) =>
            point.priceMissing ? colors.backgroundPrimary : colors.chart1,
          )
          if (!isDeepEqual(newZones, cacheZones)) {
            cacheZones = structuredClone(newZones)
            this.series[0].update({ type: chartType, zones: newZones })
          }
        },
      },
    },
    title: {
      text: undefined,
    },
    series: [
      {
        type: chartType,
        name: 'spot',
        zoneAxis: 'x',
        zones: cacheZones,

        accessibility: {
          point: {
            descriptionFormatter: (point) => {
              const contextPoint = point as SpotGraphContextPoint
              return `${getYearlyToolTipheading(new Date(contextPoint.time), locale)}, ${contextPoint.y || 0} ${contextPoint.unit}`
            },
          },
        },
        animation: false,
        color: colors.oceanGreen,
        data: spotGraphData,

        states: {
          hover: {
            lineWidth: 3,
          },
        },
        yAxis: 0,
      },
    ],
    plotOptions: {
      series: {
        marker: {
          states: {
            hover: {
              enabled: false,
            },
          },
        },
      },
    },
    yAxis: {
      tickAmount: TICK_AMOUNT,
      labels: {
        style: {
          fontSize: isMobile ? `12px` : `14px`,
          fontFamily: FONT_FAMILY,
          color: colors.text,
        },
        y: 3,
      },
      min: 0,
      max: isEmpty ? 100 : undefined,
      title: {
        style: {
          fontSize: isMobile ? `12px` : `14px`,
          fontFamily: FONT_FAMILY,
          color: colors.text,
        },
        text: 'öre/kWh',
        offset: 0,
        y: -22,
        x: 13,
        rotation: 0,
        align: 'high',
      },
    },

    xAxis: {
      crosshair: isMobile
        ? {
            color: 'transparent',
            width: 0.1,
          }
        : {
            color: colors.text,
            dashStyle: 'Dash',
            width: 1,
          },
      offset: -10,
      lineWidth: 0,
      title: {
        text: null,
      },
      labels: {
        step: isMobile ? 2 : 1,
        autoRotation: [0],
        style: {
          fontSize: isMobile ? `12px` : `14px`,
          fontFamily: FONT_FAMILY,
          color: colors.text,
        },
      },
      categories,
      tickWidth: 0,
    },
    tooltip: {
      animation: false,
      shadow: false,
      borderWidth: 0,
      borderRadius: 0,
      padding: 0,
      shared: true,
      shape: 'rect',
      backgroundColor: undefined,
      hideDelay: 100,
      formatter: function () {
        const point = this as SpotGraphContextPoint
        const date = new Date(point.time)
        const heading = getYearlyToolTipheading(date, locale)
        return SpotTooltip({
          heading,
          price: point.effectivePrice
            ? number(point.effectivePrice, { minimumFractionDigits: 2, maximumFractionDigits: 2 })
            : '',
          unit: point.unit,
          color: getPositiveNegativeColorFromPrice(point.effectivePrice, average),
          colors,
          rendererMode: isMobile ? 'default' : 'tablet',
          labels: { dataNotAvailable: t('dataNotAvailable') },
        })
      },
      useHTML: true,
      outside: true,
      positioner: isMobile
        ? function (_, height) {
            const thiz = this as unknown as Highcharts.Tooltip
            const chart = thiz.chart
            const chartPosition = chart.pointer.getChartPosition()
            const tooltipPosition = document
              .querySelector(`#${TooltipContainerId}`)
              ?.getBoundingClientRect()

            handleScrollEvent(chart)

            return {
              x: -chartPosition.left + (tooltipPosition?.left ?? 0),
              y: chart.plotTop - height - 65,
            }
          }
        : undefined,
    },
  }

  return config
}
